<div id="frontpage">
  <div id="main">
    <div id="header-frontpage">
    </div>
    <div style="text-align: center; margin-left: 60px; margin-right: 60px;">
      As Cute As It Gets thanks you for your interest in our business but we are no longer in operation.
      <br />&nbsp;<br />
      If you are an existing client and would like to purchase copies of old sessions please contact us at <a href="mailto:info@ascuteasitgets.com">info@ascuteasitgets.com</a>.
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
      <br />&nbsp;<br />
  </div>
</div>
</div>
<div id="copyright-frontpage">© Copyright As Cute As It Gets Portrait Design 2002-2021</div>
